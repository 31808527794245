import React, { Fragment, useEffect, useState } from "react"
import * as Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import dynamic from 'next/dynamic'

const ChevronLeft = dynamic(() => import('lucide-react').then((mod) => mod.ChevronLeft), { ssr: false })
const ChevronRight = dynamic(() => import('lucide-react').then((mod) => mod.ChevronRight), { ssr: false })

import CustomerReviewCard, { ReviewCardProps } from "./CustomerReviewCard"

import {
  CustomerReviewCardSliderSegment,
  CustomerReviewCardSliderHeader,
  GoogelReviewSummary,
  RatingNumber,
  RatingStars,
  GoogleLogoIcon,
  RatingMessage,
  GoogleLogoWrapper,
  CustomerReviewSliderArrowContainer,
  CustomerReviewSubtitle,
} from "./CustomerReviewCardSlider.styles"

import { IoIosStarHalf, IoIosStarOutline, IoIosStar } from "react-icons/io"
import { Container, Grid, Icon } from "semantic-ui-react"
import { H6 } from "@styles/Global.styles"
import theme from "~/styles/theme"

export interface ReviewCardSliderProps {
  title?: string,
  subtitle?: string,
  title_2?: string,
  backgroundColor?: string|string[]
  sliders?: ReviewCardProps[]
  rating?: number
  totalReviews?: number
  hasGoogleReview?: boolean
}

export const DisplayRating = (rating: number, fontColor: string) => {
  //if 4.8 above it should be 5 solid star.
  var rRating = rating >= 4.8 ? 5 : rating
  var solidRatingNum = Math.floor(rRating)
  let result = []
  for (let count = 0; count < 5; count++) {
    if (count < solidRatingNum) {
      result.push(
        <IoIosStar key={count} style={{ fontSize: "16px" }} color={fontColor} />
      )
    } else if (count == solidRatingNum) {
      result.push(
        <IoIosStarHalf
          key={count}
          style={{ fontSize: "16px" }}
          color={fontColor}
        />
      )
    } else {
      result.push(
        <IoIosStarOutline
          key={count}
          style={{ fontSize: "20px" }}
          color={fontColor}
        />
      )
    }
  }
  return <Fragment>{result}</Fragment>
}

type ArrowProps = {
  onClick?: any
}

export const PrevArrow = (props: ArrowProps) => {
  const { onClick } = props
  return (
    <CustomerReviewSliderArrowContainer arrowSide="left" onClick={onClick}>
      <ChevronLeft size={40} color={theme.brand.colors.darkGrey} strokeWidth={3} />
    </CustomerReviewSliderArrowContainer>
  )
}
export const NextArrow = (props: ArrowProps) => {
  const { onClick } = props
  return (
    <CustomerReviewSliderArrowContainer arrowSide="right" onClick={onClick}>
      <ChevronRight size={40} color={theme.brand.colors.darkGrey} strokeWidth={3}/>
    </CustomerReviewSliderArrowContainer>
  )
}

const CustomerReviewCardSlider = ({
  title,
  title_2,
  subtitle,
  backgroundColor,
  sliders,
  rating,
  totalReviews,
  hasGoogleReview
}: ReviewCardSliderProps) => {
  
  const [isClient, setIsClient] = useState(false)
  const ReactSlick = Slider.default

  useEffect(() => {
    setIsClient(true)
  }, [])

  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  }

  if (!isClient) return null
  
  return (
    <CustomerReviewCardSliderSegment
      backgroundColor={backgroundColor}
      className="customer-review-cards"
    >
      <Container>
        <Grid columns={2} stackable stretched>
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <CustomerReviewSubtitle>{subtitle}</CustomerReviewSubtitle>
            {title_2 && <CustomerReviewCardSliderHeader dangerouslySetInnerHTML={{__html: title_2.replaceAll("code", "span")}}></CustomerReviewCardSliderHeader>}
          </Grid.Column>
          <Grid.Column  mobile={16} tablet={6} computer={6}>
              {hasGoogleReview && <GoogelReviewSummary>
              <GoogleLogoWrapper
                target="_blank"
                href="https://www.google.com/search?q=KiwiPlates&oq=KiwiPlates&aqs=chrome..69i57j69i59j0i271l3j69i60l3.1782j0j15&sourceid=chrome&ie=UTF-8#lrd=0x6d0d48057eb92787:0xcb1617cfb7860625,1,,,"
              >
                <GoogleLogoIcon>
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 256 262"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                  >
                    <path
                      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                      fill="#4285F4"
                    />
                    <path
                      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                      fill="#34A853"
                    />
                    <path
                      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                      fill="#FBBC05"
                    />
                    <path
                      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                      fill="#EB4335"
                    />
                  </svg>
                </GoogleLogoIcon>
              </GoogleLogoWrapper>
              <div>
                <H6>Google Rating</H6>
                <div>
                  <RatingNumber>{rating}</RatingNumber>
                  {rating && <RatingStars>{DisplayRating(rating, "orange")}</RatingStars>}
                </div>
                <RatingMessage>Based on {totalReviews} Reviews</RatingMessage>
              </div>
            </GoogelReviewSummary>}
          </Grid.Column>
        </Grid>   
        <ReactSlick {...settings}>
          {sliders && sliders.map((data, key) => {
            return <CustomerReviewCard {...data} key={key} />
          })}
        </ReactSlick>
      </Container>
    </CustomerReviewCardSliderSegment>
  )
}
export default CustomerReviewCardSlider
